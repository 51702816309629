/**
 * @fileoverview gRPC-Web generated client stub for pbs
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.11.4
// source: user.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var common_pb = require('./common_pb.js')

var role_pb = require('./role_pb.js')

var company_pb = require('./company_pb.js')
const proto = {};
proto.pbs = require('./user_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pbs.UserServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pbs.UserServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.LoginUser,
 *   !proto.pbs.LoginResp>}
 */
const methodDescriptor_UserService_Login = new grpc.web.MethodDescriptor(
  '/pbs.UserService/Login',
  grpc.web.MethodType.UNARY,
  proto.pbs.LoginUser,
  proto.pbs.LoginResp,
  /**
   * @param {!proto.pbs.LoginUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.LoginResp.deserializeBinary
);


/**
 * @param {!proto.pbs.LoginUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pbs.LoginResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.LoginResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.login =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/Login',
      request,
      metadata || {},
      methodDescriptor_UserService_Login,
      callback);
};


/**
 * @param {!proto.pbs.LoginUser} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.LoginResp>}
 *     Promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.login =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/Login',
      request,
      metadata || {},
      methodDescriptor_UserService_Login);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Empty,
 *   !proto.pbs.CodeResp>}
 */
const methodDescriptor_UserService_GetCode = new grpc.web.MethodDescriptor(
  '/pbs.UserService/GetCode',
  grpc.web.MethodType.UNARY,
  common_pb.Empty,
  proto.pbs.CodeResp,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.CodeResp.deserializeBinary
);


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pbs.CodeResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.CodeResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.getCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/GetCode',
      request,
      metadata || {},
      methodDescriptor_UserService_GetCode,
      callback);
};


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.CodeResp>}
 *     Promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.getCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/GetCode',
      request,
      metadata || {},
      methodDescriptor_UserService_GetCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.User,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_UserService_Update = new grpc.web.MethodDescriptor(
  '/pbs.UserService/Update',
  grpc.web.MethodType.UNARY,
  proto.pbs.User,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/Update',
      request,
      metadata || {},
      methodDescriptor_UserService_Update,
      callback);
};


/**
 * @param {!proto.pbs.User} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     Promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/Update',
      request,
      metadata || {},
      methodDescriptor_UserService_Update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.ChangePasswordReq,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_UserService_ChangePassword = new grpc.web.MethodDescriptor(
  '/pbs.UserService/ChangePassword',
  grpc.web.MethodType.UNARY,
  proto.pbs.ChangePasswordReq,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.ChangePasswordReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.ChangePasswordReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.changePassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/ChangePassword',
      request,
      metadata || {},
      methodDescriptor_UserService_ChangePassword,
      callback);
};


/**
 * @param {!proto.pbs.ChangePasswordReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     Promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.changePassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/ChangePassword',
      request,
      metadata || {},
      methodDescriptor_UserService_ChangePassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.RegisterReq,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_UserService_Register = new grpc.web.MethodDescriptor(
  '/pbs.UserService/Register',
  grpc.web.MethodType.UNARY,
  proto.pbs.RegisterReq,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.RegisterReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.RegisterReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.register =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/Register',
      request,
      metadata || {},
      methodDescriptor_UserService_Register,
      callback);
};


/**
 * @param {!proto.pbs.RegisterReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     Promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.register =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/Register',
      request,
      metadata || {},
      methodDescriptor_UserService_Register);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.SendCodeReq,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_UserService_SendCode = new grpc.web.MethodDescriptor(
  '/pbs.UserService/SendCode',
  grpc.web.MethodType.UNARY,
  proto.pbs.SendCodeReq,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.SendCodeReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.SendCodeReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.sendCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/SendCode',
      request,
      metadata || {},
      methodDescriptor_UserService_SendCode,
      callback);
};


/**
 * @param {!proto.pbs.SendCodeReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     Promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.sendCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/SendCode',
      request,
      metadata || {},
      methodDescriptor_UserService_SendCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.SendCodeByMethodReq,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_UserService_SendCodeByMethod = new grpc.web.MethodDescriptor(
  '/pbs.UserService/SendCodeByMethod',
  grpc.web.MethodType.UNARY,
  proto.pbs.SendCodeByMethodReq,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.SendCodeByMethodReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.SendCodeByMethodReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.sendCodeByMethod =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/SendCodeByMethod',
      request,
      metadata || {},
      methodDescriptor_UserService_SendCodeByMethod,
      callback);
};


/**
 * @param {!proto.pbs.SendCodeByMethodReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     Promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.sendCodeByMethod =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/SendCodeByMethod',
      request,
      metadata || {},
      methodDescriptor_UserService_SendCodeByMethod);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.LoginUser,
 *   !proto.pbs.User>}
 */
const methodDescriptor_UserService_AdminLogin = new grpc.web.MethodDescriptor(
  '/pbs.UserService/AdminLogin',
  grpc.web.MethodType.UNARY,
  proto.pbs.LoginUser,
  proto.pbs.User,
  /**
   * @param {!proto.pbs.LoginUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.User.deserializeBinary
);


/**
 * @param {!proto.pbs.LoginUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pbs.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.adminLogin =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/AdminLogin',
      request,
      metadata || {},
      methodDescriptor_UserService_AdminLogin,
      callback);
};


/**
 * @param {!proto.pbs.LoginUser} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.User>}
 *     Promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.adminLogin =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/AdminLogin',
      request,
      metadata || {},
      methodDescriptor_UserService_AdminLogin);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.UserListReq,
 *   !proto.pbs.Users>}
 */
const methodDescriptor_UserService_UserList = new grpc.web.MethodDescriptor(
  '/pbs.UserService/UserList',
  grpc.web.MethodType.UNARY,
  proto.pbs.UserListReq,
  proto.pbs.Users,
  /**
   * @param {!proto.pbs.UserListReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Users.deserializeBinary
);


/**
 * @param {!proto.pbs.UserListReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pbs.Users)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Users>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.userList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/UserList',
      request,
      metadata || {},
      methodDescriptor_UserService_UserList,
      callback);
};


/**
 * @param {!proto.pbs.UserListReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Users>}
 *     Promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.userList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/UserList',
      request,
      metadata || {},
      methodDescriptor_UserService_UserList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.User,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_UserService_Create = new grpc.web.MethodDescriptor(
  '/pbs.UserService/Create',
  grpc.web.MethodType.UNARY,
  proto.pbs.User,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/Create',
      request,
      metadata || {},
      methodDescriptor_UserService_Create,
      callback);
};


/**
 * @param {!proto.pbs.User} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     Promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/Create',
      request,
      metadata || {},
      methodDescriptor_UserService_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.UserId,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_UserService_Delete = new grpc.web.MethodDescriptor(
  '/pbs.UserService/Delete',
  grpc.web.MethodType.UNARY,
  proto.pbs.UserId,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.UserId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.UserId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/Delete',
      request,
      metadata || {},
      methodDescriptor_UserService_Delete,
      callback);
};


/**
 * @param {!proto.pbs.UserId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     Promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/Delete',
      request,
      metadata || {},
      methodDescriptor_UserService_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.User,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_UserService_Edit = new grpc.web.MethodDescriptor(
  '/pbs.UserService/Edit',
  grpc.web.MethodType.UNARY,
  proto.pbs.User,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.edit =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/Edit',
      request,
      metadata || {},
      methodDescriptor_UserService_Edit,
      callback);
};


/**
 * @param {!proto.pbs.User} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     Promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.edit =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/Edit',
      request,
      metadata || {},
      methodDescriptor_UserService_Edit);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.UserId,
 *   !proto.pbs.User>}
 */
const methodDescriptor_UserService_GetUserInfo = new grpc.web.MethodDescriptor(
  '/pbs.UserService/GetUserInfo',
  grpc.web.MethodType.UNARY,
  proto.pbs.UserId,
  proto.pbs.User,
  /**
   * @param {!proto.pbs.UserId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.User.deserializeBinary
);


/**
 * @param {!proto.pbs.UserId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pbs.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.getUserInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/GetUserInfo',
      request,
      metadata || {},
      methodDescriptor_UserService_GetUserInfo,
      callback);
};


/**
 * @param {!proto.pbs.UserId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.User>}
 *     Promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.getUserInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/GetUserInfo',
      request,
      metadata || {},
      methodDescriptor_UserService_GetUserInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.GetClientAndSaleReq,
 *   !proto.pbs.GetClientAndSaleResp>}
 */
const methodDescriptor_UserService_GetClientAndSale = new grpc.web.MethodDescriptor(
  '/pbs.UserService/GetClientAndSale',
  grpc.web.MethodType.UNARY,
  proto.pbs.GetClientAndSaleReq,
  proto.pbs.GetClientAndSaleResp,
  /**
   * @param {!proto.pbs.GetClientAndSaleReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.GetClientAndSaleResp.deserializeBinary
);


/**
 * @param {!proto.pbs.GetClientAndSaleReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pbs.GetClientAndSaleResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.GetClientAndSaleResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.UserServiceClient.prototype.getClientAndSale =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.UserService/GetClientAndSale',
      request,
      metadata || {},
      methodDescriptor_UserService_GetClientAndSale,
      callback);
};


/**
 * @param {!proto.pbs.GetClientAndSaleReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.GetClientAndSaleResp>}
 *     Promise that resolves to the response
 */
proto.pbs.UserServicePromiseClient.prototype.getClientAndSale =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.UserService/GetClientAndSale',
      request,
      metadata || {},
      methodDescriptor_UserService_GetClientAndSale);
};


module.exports = proto.pbs;

