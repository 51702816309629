import Grpc from '@/utils/http/request';

import {
	SendCodeByMethodReq
} from "@/pbs/user_pb";
import {
	UserServiceClient
} from "@/pbs/user_grpc_web_pb";


class User extends Grpc {
	constructor() {
		super(UserServiceClient);
	}
	
	/**
	 * 获取验证码
	 * @param mobile {string} 发送目的地信息(手机号/电子邮件地址) 
	 * @param type {Number} 验证码发送方式(1通过手机短信;2通过电子邮件)  
	 * @param eid {string} 展会id 可空  
	 * @returns {Promise}
	 */
	VerificCode(mobile,type,eid) {
		return new Promise((resolve, reject) => {
			var request = new SendCodeByMethodReq();
			request.setMethodType(type);
			request.setDestination(mobile);
			request.setExhibitionId(eid);
			this.service.sendCodeByMethod(request, this.metadata, (err, response) => {
				if (err) {
					reject(err.message);
				} else {
					resolve(response);
				}
			});
		});
	}
}

export default new User();
