// source: user.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
var role_pb = require('./role_pb.js');
goog.object.extend(proto, role_pb);
var company_pb = require('./company_pb.js');
goog.object.extend(proto, company_pb);
goog.exportSymbol('proto.pbs.ChangePasswordReq', null, global);
goog.exportSymbol('proto.pbs.CodeResp', null, global);
goog.exportSymbol('proto.pbs.GetClientAndSaleReq', null, global);
goog.exportSymbol('proto.pbs.GetClientAndSaleResp', null, global);
goog.exportSymbol('proto.pbs.LoginResp', null, global);
goog.exportSymbol('proto.pbs.LoginUser', null, global);
goog.exportSymbol('proto.pbs.RegisterReq', null, global);
goog.exportSymbol('proto.pbs.SendCodeByMethodReq', null, global);
goog.exportSymbol('proto.pbs.SendCodeReq', null, global);
goog.exportSymbol('proto.pbs.User', null, global);
goog.exportSymbol('proto.pbs.UserId', null, global);
goog.exportSymbol('proto.pbs.UserListReq', null, global);
goog.exportSymbol('proto.pbs.Users', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.UserId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.UserId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.UserId.displayName = 'proto.pbs.UserId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.LoginUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.LoginUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.LoginUser.displayName = 'proto.pbs.LoginUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.LoginResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.LoginResp.repeatedFields_, null);
};
goog.inherits(proto.pbs.LoginResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.LoginResp.displayName = 'proto.pbs.LoginResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.GetClientAndSaleReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.GetClientAndSaleReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.GetClientAndSaleReq.displayName = 'proto.pbs.GetClientAndSaleReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.GetClientAndSaleResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.GetClientAndSaleResp.repeatedFields_, null);
};
goog.inherits(proto.pbs.GetClientAndSaleResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.GetClientAndSaleResp.displayName = 'proto.pbs.GetClientAndSaleResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.UserListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.UserListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.UserListReq.displayName = 'proto.pbs.UserListReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ChangePasswordReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ChangePasswordReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ChangePasswordReq.displayName = 'proto.pbs.ChangePasswordReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.RegisterReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.RegisterReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.RegisterReq.displayName = 'proto.pbs.RegisterReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SendCodeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.SendCodeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SendCodeReq.displayName = 'proto.pbs.SendCodeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SendCodeByMethodReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.SendCodeByMethodReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SendCodeByMethodReq.displayName = 'proto.pbs.SendCodeByMethodReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CodeResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.CodeResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CodeResp.displayName = 'proto.pbs.CodeResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.Users = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.Users.repeatedFields_, null);
};
goog.inherits(proto.pbs.Users, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.Users.displayName = 'proto.pbs.Users';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.User.repeatedFields_, null);
};
goog.inherits(proto.pbs.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.User.displayName = 'proto.pbs.User';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.UserId.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.UserId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.UserId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.UserId.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.UserId}
 */
proto.pbs.UserId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.UserId;
  return proto.pbs.UserId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.UserId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.UserId}
 */
proto.pbs.UserId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.UserId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.UserId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.UserId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.UserId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.pbs.UserId.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UserId} returns this
 */
proto.pbs.UserId.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.LoginUser.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.LoginUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.LoginUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LoginUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    userName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.LoginUser}
 */
proto.pbs.LoginUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.LoginUser;
  return proto.pbs.LoginUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.LoginUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.LoginUser}
 */
proto.pbs.LoginUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.LoginUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.LoginUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.LoginUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LoginUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string user_name = 1;
 * @return {string}
 */
proto.pbs.LoginUser.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LoginUser} returns this
 */
proto.pbs.LoginUser.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.pbs.LoginUser.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LoginUser} returns this
 */
proto.pbs.LoginUser.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.pbs.LoginUser.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LoginUser} returns this
 */
proto.pbs.LoginUser.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.LoginResp.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.LoginResp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.LoginResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.LoginResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LoginResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userFname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    gender: jspb.Message.getFieldWithDefault(msg, 4, 0),
    avatar: jspb.Message.getFieldWithDefault(msg, 5, ""),
    userType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    email: jspb.Message.getFieldWithDefault(msg, 7, ""),
    mobile: jspb.Message.getFieldWithDefault(msg, 8, ""),
    token: jspb.Message.getFieldWithDefault(msg, 9, ""),
    company: (f = msg.getCompany()) && company_pb.Company.toObject(includeInstance, f),
    saleOrClientListList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    exhibitionId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    jobPosition: jspb.Message.getFieldWithDefault(msg, 13, ""),
    personalProfile: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.LoginResp}
 */
proto.pbs.LoginResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.LoginResp;
  return proto.pbs.LoginResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.LoginResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.LoginResp}
 */
proto.pbs.LoginResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserFname(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGender(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatar(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobile(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 10:
      var value = new company_pb.Company;
      reader.readMessage(value,company_pb.Company.deserializeBinaryFromReader);
      msg.setCompany(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addSaleOrClientList(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setExhibitionId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobPosition(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPersonalProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.LoginResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.LoginResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.LoginResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LoginResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserFname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGender();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getAvatar();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUserType();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMobile();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCompany();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      company_pb.Company.serializeBinaryToWriter
    );
  }
  f = message.getSaleOrClientListList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getExhibitionId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getJobPosition();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPersonalProfile();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.pbs.LoginResp.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LoginResp} returns this
 */
proto.pbs.LoginResp.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_name = 2;
 * @return {string}
 */
proto.pbs.LoginResp.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LoginResp} returns this
 */
proto.pbs.LoginResp.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_fname = 3;
 * @return {string}
 */
proto.pbs.LoginResp.prototype.getUserFname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LoginResp} returns this
 */
proto.pbs.LoginResp.prototype.setUserFname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 gender = 4;
 * @return {number}
 */
proto.pbs.LoginResp.prototype.getGender = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.LoginResp} returns this
 */
proto.pbs.LoginResp.prototype.setGender = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string avatar = 5;
 * @return {string}
 */
proto.pbs.LoginResp.prototype.getAvatar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LoginResp} returns this
 */
proto.pbs.LoginResp.prototype.setAvatar = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 user_type = 6;
 * @return {number}
 */
proto.pbs.LoginResp.prototype.getUserType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.LoginResp} returns this
 */
proto.pbs.LoginResp.prototype.setUserType = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string email = 7;
 * @return {string}
 */
proto.pbs.LoginResp.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LoginResp} returns this
 */
proto.pbs.LoginResp.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string mobile = 8;
 * @return {string}
 */
proto.pbs.LoginResp.prototype.getMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LoginResp} returns this
 */
proto.pbs.LoginResp.prototype.setMobile = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string token = 9;
 * @return {string}
 */
proto.pbs.LoginResp.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LoginResp} returns this
 */
proto.pbs.LoginResp.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional Company company = 10;
 * @return {?proto.pbs.Company}
 */
proto.pbs.LoginResp.prototype.getCompany = function() {
  return /** @type{?proto.pbs.Company} */ (
    jspb.Message.getWrapperField(this, company_pb.Company, 10));
};


/**
 * @param {?proto.pbs.Company|undefined} value
 * @return {!proto.pbs.LoginResp} returns this
*/
proto.pbs.LoginResp.prototype.setCompany = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.LoginResp} returns this
 */
proto.pbs.LoginResp.prototype.clearCompany = function() {
  return this.setCompany(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.LoginResp.prototype.hasCompany = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated string sale_or_client_list = 11;
 * @return {!Array<string>}
 */
proto.pbs.LoginResp.prototype.getSaleOrClientListList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.LoginResp} returns this
 */
proto.pbs.LoginResp.prototype.setSaleOrClientListList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.LoginResp} returns this
 */
proto.pbs.LoginResp.prototype.addSaleOrClientList = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.LoginResp} returns this
 */
proto.pbs.LoginResp.prototype.clearSaleOrClientListList = function() {
  return this.setSaleOrClientListList([]);
};


/**
 * optional string exhibition_id = 12;
 * @return {string}
 */
proto.pbs.LoginResp.prototype.getExhibitionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LoginResp} returns this
 */
proto.pbs.LoginResp.prototype.setExhibitionId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string job_position = 13;
 * @return {string}
 */
proto.pbs.LoginResp.prototype.getJobPosition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LoginResp} returns this
 */
proto.pbs.LoginResp.prototype.setJobPosition = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string personal_profile = 14;
 * @return {string}
 */
proto.pbs.LoginResp.prototype.getPersonalProfile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LoginResp} returns this
 */
proto.pbs.LoginResp.prototype.setPersonalProfile = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.GetClientAndSaleReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.GetClientAndSaleReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.GetClientAndSaleReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.GetClientAndSaleReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.GetClientAndSaleReq}
 */
proto.pbs.GetClientAndSaleReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.GetClientAndSaleReq;
  return proto.pbs.GetClientAndSaleReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.GetClientAndSaleReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.GetClientAndSaleReq}
 */
proto.pbs.GetClientAndSaleReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.GetClientAndSaleReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.GetClientAndSaleReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.GetClientAndSaleReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.GetClientAndSaleReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string company_id = 1;
 * @return {string}
 */
proto.pbs.GetClientAndSaleReq.prototype.getCompanyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.GetClientAndSaleReq} returns this
 */
proto.pbs.GetClientAndSaleReq.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.GetClientAndSaleResp.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.GetClientAndSaleResp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.GetClientAndSaleResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.GetClientAndSaleResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.GetClientAndSaleResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientsList: jspb.Message.toObjectList(msg.getClientsList(),
    proto.pbs.User.toObject, includeInstance),
    salesList: jspb.Message.toObjectList(msg.getSalesList(),
    proto.pbs.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.GetClientAndSaleResp}
 */
proto.pbs.GetClientAndSaleResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.GetClientAndSaleResp;
  return proto.pbs.GetClientAndSaleResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.GetClientAndSaleResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.GetClientAndSaleResp}
 */
proto.pbs.GetClientAndSaleResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.User;
      reader.readMessage(value,proto.pbs.User.deserializeBinaryFromReader);
      msg.addClients(value);
      break;
    case 2:
      var value = new proto.pbs.User;
      reader.readMessage(value,proto.pbs.User.deserializeBinaryFromReader);
      msg.addSales(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.GetClientAndSaleResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.GetClientAndSaleResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.GetClientAndSaleResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.GetClientAndSaleResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.User.serializeBinaryToWriter
    );
  }
  f = message.getSalesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.pbs.User.serializeBinaryToWriter
    );
  }
};


/**
 * repeated User clients = 1;
 * @return {!Array<!proto.pbs.User>}
 */
proto.pbs.GetClientAndSaleResp.prototype.getClientsList = function() {
  return /** @type{!Array<!proto.pbs.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.User, 1));
};


/**
 * @param {!Array<!proto.pbs.User>} value
 * @return {!proto.pbs.GetClientAndSaleResp} returns this
*/
proto.pbs.GetClientAndSaleResp.prototype.setClientsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.User}
 */
proto.pbs.GetClientAndSaleResp.prototype.addClients = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.GetClientAndSaleResp} returns this
 */
proto.pbs.GetClientAndSaleResp.prototype.clearClientsList = function() {
  return this.setClientsList([]);
};


/**
 * repeated User sales = 2;
 * @return {!Array<!proto.pbs.User>}
 */
proto.pbs.GetClientAndSaleResp.prototype.getSalesList = function() {
  return /** @type{!Array<!proto.pbs.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.User, 2));
};


/**
 * @param {!Array<!proto.pbs.User>} value
 * @return {!proto.pbs.GetClientAndSaleResp} returns this
*/
proto.pbs.GetClientAndSaleResp.prototype.setSalesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.pbs.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.User}
 */
proto.pbs.GetClientAndSaleResp.prototype.addSales = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.pbs.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.GetClientAndSaleResp} returns this
 */
proto.pbs.GetClientAndSaleResp.prototype.clearSalesList = function() {
  return this.setSalesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.UserListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.UserListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.UserListReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.UserListReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    keyword: jspb.Message.getFieldWithDefault(msg, 1, ""),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0),
    userType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    companyId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.UserListReq}
 */
proto.pbs.UserListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.UserListReq;
  return proto.pbs.UserListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.UserListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.UserListReq}
 */
proto.pbs.UserListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyword(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageSize(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.UserListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.UserListReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.UserListReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.UserListReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeyword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getUserType();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCompanyId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string keyword = 1;
 * @return {string}
 */
proto.pbs.UserListReq.prototype.getKeyword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UserListReq} returns this
 */
proto.pbs.UserListReq.prototype.setKeyword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 page = 2;
 * @return {number}
 */
proto.pbs.UserListReq.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.UserListReq} returns this
 */
proto.pbs.UserListReq.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 page_size = 3;
 * @return {number}
 */
proto.pbs.UserListReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.UserListReq} returns this
 */
proto.pbs.UserListReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 user_type = 4;
 * @return {number}
 */
proto.pbs.UserListReq.prototype.getUserType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.UserListReq} returns this
 */
proto.pbs.UserListReq.prototype.setUserType = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string company_id = 5;
 * @return {string}
 */
proto.pbs.UserListReq.prototype.getCompanyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UserListReq} returns this
 */
proto.pbs.UserListReq.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ChangePasswordReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ChangePasswordReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ChangePasswordReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ChangePasswordReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    password: jspb.Message.getFieldWithDefault(msg, 1, ""),
    oldPassword: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ChangePasswordReq}
 */
proto.pbs.ChangePasswordReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ChangePasswordReq;
  return proto.pbs.ChangePasswordReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ChangePasswordReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ChangePasswordReq}
 */
proto.pbs.ChangePasswordReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ChangePasswordReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ChangePasswordReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ChangePasswordReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ChangePasswordReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOldPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string password = 1;
 * @return {string}
 */
proto.pbs.ChangePasswordReq.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ChangePasswordReq} returns this
 */
proto.pbs.ChangePasswordReq.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string old_password = 2;
 * @return {string}
 */
proto.pbs.ChangePasswordReq.prototype.getOldPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ChangePasswordReq} returns this
 */
proto.pbs.ChangePasswordReq.prototype.setOldPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.RegisterReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.RegisterReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.RegisterReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.RegisterReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    mobile: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 3, ""),
    avatar: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.RegisterReq}
 */
proto.pbs.RegisterReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.RegisterReq;
  return proto.pbs.RegisterReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.RegisterReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.RegisterReq}
 */
proto.pbs.RegisterReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobile(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatar(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.RegisterReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.RegisterReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.RegisterReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.RegisterReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMobile();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAvatar();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string mobile = 1;
 * @return {string}
 */
proto.pbs.RegisterReq.prototype.getMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.RegisterReq} returns this
 */
proto.pbs.RegisterReq.prototype.setMobile = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.pbs.RegisterReq.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.RegisterReq} returns this
 */
proto.pbs.RegisterReq.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.pbs.RegisterReq.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.RegisterReq} returns this
 */
proto.pbs.RegisterReq.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string avatar = 4;
 * @return {string}
 */
proto.pbs.RegisterReq.prototype.getAvatar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.RegisterReq} returns this
 */
proto.pbs.RegisterReq.prototype.setAvatar = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SendCodeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SendCodeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SendCodeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SendCodeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    mobile: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SendCodeReq}
 */
proto.pbs.SendCodeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SendCodeReq;
  return proto.pbs.SendCodeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SendCodeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SendCodeReq}
 */
proto.pbs.SendCodeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SendCodeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SendCodeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SendCodeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SendCodeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMobile();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string mobile = 1;
 * @return {string}
 */
proto.pbs.SendCodeReq.prototype.getMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SendCodeReq} returns this
 */
proto.pbs.SendCodeReq.prototype.setMobile = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SendCodeByMethodReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SendCodeByMethodReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SendCodeByMethodReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SendCodeByMethodReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    methodType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    destination: jspb.Message.getFieldWithDefault(msg, 2, ""),
    exhibitionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SendCodeByMethodReq}
 */
proto.pbs.SendCodeByMethodReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SendCodeByMethodReq;
  return proto.pbs.SendCodeByMethodReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SendCodeByMethodReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SendCodeByMethodReq}
 */
proto.pbs.SendCodeByMethodReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMethodType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestination(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExhibitionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SendCodeByMethodReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SendCodeByMethodReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SendCodeByMethodReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SendCodeByMethodReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMethodType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDestination();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExhibitionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 method_type = 1;
 * @return {number}
 */
proto.pbs.SendCodeByMethodReq.prototype.getMethodType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SendCodeByMethodReq} returns this
 */
proto.pbs.SendCodeByMethodReq.prototype.setMethodType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string destination = 2;
 * @return {string}
 */
proto.pbs.SendCodeByMethodReq.prototype.getDestination = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SendCodeByMethodReq} returns this
 */
proto.pbs.SendCodeByMethodReq.prototype.setDestination = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string exhibition_id = 3;
 * @return {string}
 */
proto.pbs.SendCodeByMethodReq.prototype.getExhibitionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SendCodeByMethodReq} returns this
 */
proto.pbs.SendCodeByMethodReq.prototype.setExhibitionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CodeResp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CodeResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CodeResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CodeResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CodeResp}
 */
proto.pbs.CodeResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CodeResp;
  return proto.pbs.CodeResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CodeResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CodeResp}
 */
proto.pbs.CodeResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CodeResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CodeResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CodeResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CodeResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.pbs.CodeResp.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.CodeResp} returns this
 */
proto.pbs.CodeResp.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.pbs.CodeResp.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.CodeResp} returns this
 */
proto.pbs.CodeResp.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.Users.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.Users.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.Users.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.Users} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Users.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.pbs.User.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.Users}
 */
proto.pbs.Users.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.Users;
  return proto.pbs.Users.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.Users} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.Users}
 */
proto.pbs.Users.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.User;
      reader.readMessage(value,proto.pbs.User.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.Users.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.Users.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.Users} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Users.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.User.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated User data = 1;
 * @return {!Array<!proto.pbs.User>}
 */
proto.pbs.Users.prototype.getDataList = function() {
  return /** @type{!Array<!proto.pbs.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.User, 1));
};


/**
 * @param {!Array<!proto.pbs.User>} value
 * @return {!proto.pbs.Users} returns this
*/
proto.pbs.Users.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.User}
 */
proto.pbs.Users.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.Users} returns this
 */
proto.pbs.Users.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.pbs.Users.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Users} returns this
 */
proto.pbs.Users.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.User.repeatedFields_ = [18];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.User.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userFname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    gender: jspb.Message.getFieldWithDefault(msg, 4, 0),
    idCard: jspb.Message.getFieldWithDefault(msg, 5, ""),
    avatar: jspb.Message.getFieldWithDefault(msg, 6, ""),
    userType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    password: jspb.Message.getFieldWithDefault(msg, 8, ""),
    email: jspb.Message.getFieldWithDefault(msg, 9, ""),
    mobile: jspb.Message.getFieldWithDefault(msg, 10, ""),
    token: jspb.Message.getFieldWithDefault(msg, 11, ""),
    status: jspb.Message.getFieldWithDefault(msg, 12, 0),
    personalProfile: jspb.Message.getFieldWithDefault(msg, 17, ""),
    jobPosition: jspb.Message.getFieldWithDefault(msg, 21, ""),
    rolesList: jspb.Message.toObjectList(msg.getRolesList(),
    role_pb.Role.toObject, includeInstance),
    userModel: jspb.Message.getFieldWithDefault(msg, 19, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 13, 0),
    deletedAt: jspb.Message.getFieldWithDefault(msg, 14, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 15, 0),
    lastLogin: jspb.Message.getFieldWithDefault(msg, 16, 0),
    company: (f = msg.getCompany()) && company_pb.Company.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.User}
 */
proto.pbs.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.User;
  return proto.pbs.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.User}
 */
proto.pbs.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserFname(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGender(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdCard(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatar(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobile(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStatus(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setPersonalProfile(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobPosition(value);
      break;
    case 18:
      var value = new role_pb.Role;
      reader.readMessage(value,role_pb.Role.deserializeBinaryFromReader);
      msg.addRoles(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserModel(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeletedAt(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastLogin(value);
      break;
    case 20:
      var value = new company_pb.Company;
      reader.readMessage(value,company_pb.Company.deserializeBinaryFromReader);
      msg.setCompany(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserFname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGender();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getIdCard();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAvatar();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUserType();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getMobile();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getPersonalProfile();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getJobPosition();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      role_pb.Role.serializeBinaryToWriter
    );
  }
  f = message.getUserModel();
  if (f !== 0) {
    writer.writeUint32(
      19,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getDeletedAt();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getLastLogin();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getCompany();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      company_pb.Company.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.pbs.User.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_name = 2;
 * @return {string}
 */
proto.pbs.User.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_fname = 3;
 * @return {string}
 */
proto.pbs.User.prototype.getUserFname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setUserFname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 gender = 4;
 * @return {number}
 */
proto.pbs.User.prototype.getGender = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setGender = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string id_card = 5;
 * @return {string}
 */
proto.pbs.User.prototype.getIdCard = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setIdCard = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string avatar = 6;
 * @return {string}
 */
proto.pbs.User.prototype.getAvatar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setAvatar = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional uint32 user_type = 7;
 * @return {number}
 */
proto.pbs.User.prototype.getUserType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setUserType = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string password = 8;
 * @return {string}
 */
proto.pbs.User.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string email = 9;
 * @return {string}
 */
proto.pbs.User.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string mobile = 10;
 * @return {string}
 */
proto.pbs.User.prototype.getMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setMobile = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string token = 11;
 * @return {string}
 */
proto.pbs.User.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional uint32 status = 12;
 * @return {number}
 */
proto.pbs.User.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string personal_profile = 17;
 * @return {string}
 */
proto.pbs.User.prototype.getPersonalProfile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setPersonalProfile = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string job_position = 21;
 * @return {string}
 */
proto.pbs.User.prototype.getJobPosition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setJobPosition = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * repeated Role Roles = 18;
 * @return {!Array<!proto.pbs.Role>}
 */
proto.pbs.User.prototype.getRolesList = function() {
  return /** @type{!Array<!proto.pbs.Role>} */ (
    jspb.Message.getRepeatedWrapperField(this, role_pb.Role, 18));
};


/**
 * @param {!Array<!proto.pbs.Role>} value
 * @return {!proto.pbs.User} returns this
*/
proto.pbs.User.prototype.setRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.pbs.Role=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Role}
 */
proto.pbs.User.prototype.addRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.pbs.Role, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};


/**
 * optional uint32 user_model = 19;
 * @return {number}
 */
proto.pbs.User.prototype.getUserModel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setUserModel = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int64 updated_at = 13;
 * @return {number}
 */
proto.pbs.User.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 deleted_at = 14;
 * @return {number}
 */
proto.pbs.User.prototype.getDeletedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setDeletedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 created_at = 15;
 * @return {number}
 */
proto.pbs.User.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int64 last_login = 16;
 * @return {number}
 */
proto.pbs.User.prototype.getLastLogin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.setLastLogin = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional Company company = 20;
 * @return {?proto.pbs.Company}
 */
proto.pbs.User.prototype.getCompany = function() {
  return /** @type{?proto.pbs.Company} */ (
    jspb.Message.getWrapperField(this, company_pb.Company, 20));
};


/**
 * @param {?proto.pbs.Company|undefined} value
 * @return {!proto.pbs.User} returns this
*/
proto.pbs.User.prototype.setCompany = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.User} returns this
 */
proto.pbs.User.prototype.clearCompany = function() {
  return this.setCompany(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.User.prototype.hasCompany = function() {
  return jspb.Message.getField(this, 20) != null;
};


goog.object.extend(exports, proto.pbs);
