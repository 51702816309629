import md5 from 'js-md5';

class Grpc {
	metadata = {session:'',token:''};//meta信息
	service; //grpc服务
	constructor(ServiceClient) {
		this.service = new ServiceClient(process.env.VUE_APP_BASE_API,this.metadata,null);
	}
	
	getToken() {
		let token = sessionStorage.getItem('token');
		return token;
	}
	
	getSession() {
		let sess = sessionStorage.getItem('session');
		if (sess == '' || sess == null) {
			sess = md5(new Date().toLocaleString());
			sessionStorage.setItem('session',sess);
		}
		return sess;
	}
}

export default Grpc